body {
  background: linear-gradient(45deg, #0d1126 0%, #301748 100%);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  background: #222;
  font-weight: 800;
}

.container {
  width: 90%;
  margin: 0 auto;
}

header {
  background: #3a383d;
}

header::after {
  content: "";
  display: table;
  clear: both;
}

.logo {
  float: left;
  padding: 10px 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(209, 209, 202);
}

nav {
  float: right;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav li {
  display: inline-block;
  margin-left: 70px;
  padding-top: 23px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
}

nav a {
  color: rgb(247, 230, 230);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
}

nav a:hover {
  color: #000;
}

nav a::before {
  content: "";
  display: block;
  height: 5px;
  background-color: #444;

  position: absolute;
  top: 1em;
  width: 0%;

  transition: all ease-in-out 250ms;
}

nav a:hover::before {
  width: 100%;
}
