.Welcome{
  background-color: #e2e2e2 !important;
}

.welcome-title {
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
  font-size: 3rem;
  color: #334756;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.socials-logo {
  height: 3rem;
  width: 3rem;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 5rem;
}

.socials {
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
  opacity: 40%;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #e2e2e2 !important;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(15, 1, 1);
}

@media screen and (max-width: 412px) {
  .socials {
    padding-top: 10px;
    align-items: center;
    justify-content: center;
    align-self: center;
    text-align: center;
    opacity: 60%;
  }
}
