.Dashboard{

    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}

.stats{
    max-width: 100%;   
    padding: 1em;    
}

p {
    display: flex;
    justify-content: center;
    color: linen;    
}
a {
    color: #0060B6;
    text-decoration: none;
    color: linen;   
}
.statsHeader{    
      
    margin: 1vh auto 0;
    font: 2em sans-serif;
    justify-content: center;
    font-weight: bolder;
}

.statsBody{
    margin: 1vh auto 0;
    width: 90%;
    min-height: fit-content;
    background: rgb(12, 12, 12);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;    
    /* background-image: url(../src/images/moonbeam.png) */
}

.totalSupply .inflation .collatorCount .totalStaked .apprxAPY .minBond .roundLength .blockTime .unBondDuration .rewardsDistrubution{
    padding: 1em;
    display: flex;
    width: 15%
}

.content{
    background: rgb(255, 255, 255);
    display: flex;
    margin: 2em;    
    flex-direction: column;  
    flex: 1 1 100px      
}
.label{
    padding: 0.5em;
    justify-content: center;
    background: rgb(241, 27, 117);
    font-weight: bold;
    color: linen;
}
.value{
    padding: 0.5em;
    text-align: center;    
}

.As-of{
    text-align: left;
}